<template>
  <div v-loading="loaders.resource">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 >Add Support Service</h1>
      <support-service-form
        @storeSupportService="storeSupportService"
      />
    </div>
  </div>
</template>

<script>

import BackButton from "@/components/Router/BackButton";
import SupportServiceForm from "./SupportServiceForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "AddSupportService",
  components: {
    BackButton,
    SupportServiceForm
  },
  data() {
    return {
      loaders: {
        resource: false
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    /**
     * Store a new support service
     * @param event - Event Object
     */
    async storeSupportService(event) {
      let vm = this
      vm.loaders.resource = true
      var formData = new FormData()
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      try {
        await vm.$store.dispatch('_storeSupportService', {config, formData})
        vm.$notify.success({
          title: 'Success',
          message: 'Support Service created successfully.'
        })
        vm.$router.back()
      }
      catch (error) {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          dangerouslyUseHTMLString: true,
          message: message
        })
      }
      vm.loaders.resource = false
    },

  }
}
</script>

<style scoped>

</style>
