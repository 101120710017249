<template>
  <div v-loading="loaders.supportService">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Edit Support Service</h1>
      <support-service-form
        @updateSupportService="updateSupportService"
        :support-data-to-edit="response.data"
      />
    </div>
  </div>
</template>

<script>

import BackButton from "@/components/Router/BackButton";
import SupportServiceForm from "@/views/Pages/ContentSection/SupportService/SupportService/SupportServiceForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "EditSupportService",
  components: {
    BackButton, SupportServiceForm
  },
  data () {
    return {
      loaders: {
        supportService: false
      },
      response: {
        data: ''
      },
      supportServiceId: this.$route.params.id
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    /**
     * Fetch the relevant support service data
     */
    async fetchSupportService() {
      let vm = this
      vm.loaders.supportService = true
      const payload = {
        id: vm.supportServiceId
      }
      try {
        const response = await vm.$store.dispatch('_getSupportService', payload)
        vm.response.data = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          message: message
        })
      }
      vm.loaders.supportService = false
    },

    /**
     * Update the support service
     * @param {event} event - Event Object
     */
    async updateSupportService(event){
      let vm = this
      vm.loaders.supportService = true
      var formData = new FormData()
      formData.append('id', this.supportServiceId)
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      try {
        await vm.$store.dispatch('_updateSupportService', {config, formData})
        vm.$notify.success({
          title: 'Success',
          message: 'Support Service updated successfully. Always check to see how your changes look in the Baritastic app.'
        })
        vm.$router.back()
      }
      catch (error) {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          dangerouslyUseHTMLString: true,
          message: message
        })
      }
      vm.loaders.supportService = false
    },

  },
  created() {
    this.fetchSupportService()
  }
}
</script>

<style scoped>

</style>
