<template>
  <div class="forms mt-4" v-loading="loaders">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
        <card type="default" header-classes="bg-transparent">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <base-input
                :rules="{required: true}"
                name="Name"
                label="Support Service Name*"
                v-model="request.supportServiceForm.name"
                placeholder="Name">
              </base-input>
            </div>
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Select Support Service Category</label>
              <el-select
                class="w-100"
                v-model="request.supportServiceForm.category"
                placeholder="Select Category"
                @change="onChangeSupportServiceCategory"
              >
                <el-option
                  class="select-danger"
                  :value="0"
                  label="Select Category"
                  key="0">
                </el-option>
                <el-option
                  v-for="option in response.categories"
                  class="select-danger max-width-730"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Select Support Service Sub Category</label>
              <el-select
                class="w-100"
                v-model="request.supportServiceForm.subCategory"
                placeholder="Select Sub Category"
              >
                <el-option
                  class="select-danger"
                  :value="0"
                  label="Select Sub Category"
                  key="0">
                </el-option>
                <el-option
                  v-for="option in response.subCategories"
                  class="select-danger max-width-730"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id">
                </el-option>
              </el-select>
            </div>
            <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
              <div class="img-upload"
                   :class="{'w-100':!pictureExist}"
              >
                <label class="form-control-label" for="picture">Profile Image</label>
                <input type="file" id="picture" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                       class="form-control mb-1" @change="onPictureChange">
                <span class="font-size-12px">Recommended image size 500x300 px. Must be less than 500kb.</span>
              </div>
              <el-image
                v-loading="request.loaders.image"
                class="img-size pl-2"
                :class="{'d-none':!pictureExist}"
                :src="request.supportServiceForm.oldPicture"
                @load="handleLoad"
                @error="handleLoad"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <base-input label="Status*">
                <el-select
                  class="w-100"
                  label="Status"
                  v-model="request.supportServiceForm.status">
                  <el-option
                    v-for="option in dropdowns.status"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col equal-height-columns">
              <label class="form-control-label">Content*</label>
              <editor
                v-model="request.supportServiceForm.content"
                :api-key=editorApiKey
                :init=editorOption
              />
            </div>
          </div>
        </card>
      </form>
      <base-button
        @click="onSubmit('supportServiceForm')"
        class="btn-primary-action mb-4 br-4 pr-5 pl-5"
        native-type="submit"
        type="submit">
        Save
      </base-button>
    </validation-observer>
  </div>

</template>

<script>
import {store} from "@/vuex/store";
import Helper from "@/util/globalHelpers";

export default {
  name: "SupportServiceForm",
  props: [ 'supportDataToEdit', 'loaders' ],
  data () {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      request: {
        loaders: {
          image: false,
        },
        supportServiceForm: {
          name: '',
          category: 0,
          subCategory: 0,
          oldPicture: '',
          picture: '',
          status: [
            0,
            1
          ],
          content: '',
        },
      },
      response: {
        categories: [],
        subCategories: [],
      },
      dropdowns: {
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    }
  },
  mounted() {
    this.getAllSupportServiceCategories()
  },
  methods: {

    /**
     * Handle the change of Support Service Category
     * @param {number} supportServiceCategory - Support Service Category
     */
    onChangeSupportServiceCategory(supportServiceCategory) {
      if (supportServiceCategory == 0){
        this.response.subCategories = []
        this.request.supportServiceForm.subCategory = 0
      }
      else {
        this.fetchSupportServiceSubCategories(supportServiceCategory)
        this.request.supportServiceForm.subCategory = 0
      }
    },

    /**
     * Fetch relevant support service sub categories
     * @param {number} id - Id of the Support Service Category
     */
    async fetchSupportServiceSubCategories(id) {
      let vm = this;
      if (id) {
        const payload = { id }
        try {
          const response = await vm.$store.dispatch('_fetchRelevantSupportServiceSubCategories', payload);
          vm.response.subCategories = response.data.data
        } catch (error) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Support Service',
            message: message
          });
        }
      }
    },

    /**
     * Fetch all the support service categories
     */
    async getAllSupportServiceCategories() {
      let vm = this
      try {
        const response = await vm.$store.dispatch('_getAllSupportServiceCategories')
        vm.response.categories = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          message: message
        })
      }
    },

    /**
     * Redirect router and emit the relevant function
     * @param {string} formName - Form name
     */
    async onSubmit(formName) {
      let vm = this
      const isSupportServiceFormValid = await this.$refs['formValidator'].validate();
      if (isSupportServiceFormValid) {
        vm.request.supportServiceForm.content = Helper.addCustomStylingToContent(vm.request.supportServiceForm.content)
        if (this.$route.name === 'StoreSupportService') {
          vm.$emit('storeSupportService', vm.request.supportServiceForm);
        } else if (this.$route.name === 'EditSupportService') {
          vm.$emit('updateSupportService', vm.request.supportServiceForm);
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    /**
     * Save the image file into support service data
     * @param {event} e - Event object
     */
    onPictureChange(e){
      this.request.supportServiceForm.picture = e.target.files[0];
    },

    /**
     * Handles the image load
     */
    handleLoad() {
      this.request.loaders.image = false
    }
  },
  watch: {
    /**
     * Save new value to supportDataToEdit property
     * @param newVal - New value of supportDataToEdit property
     */
    supportDataToEdit(newVal) {
      this.request.supportServiceForm.name = newVal.name ?? ''
      this.request.supportServiceForm.category = newVal.support_service_category_id ?? 0
      this.request.supportServiceForm.subCategory = newVal.support_service_sub_category_id ?? 0
      this.request.supportServiceForm.oldPicture = newVal.picture ?? ''
      this.request.supportServiceForm.status = newVal.status ?? ''
      this.request.supportServiceForm.content = newVal.content ?? ''
      if (newVal.hasOwnProperty('support_service_category')) {
        this.fetchSupportServiceSubCategories(newVal.support_service_category_id);
      }
    }
  },
  computed: {
    /**
     * Computed property to check picture if exist already
     * @returns {false|string|boolean}
     */
    pictureExist() {
      return this.request.supportServiceForm.oldPicture !== ''
        && this.request.supportServiceForm.oldPicture
        && this.request.supportServiceForm.picture === ''
    }
  }
}
</script>

<style scoped>

.max-width-730{
  max-width: 730px;
}

.img-size{
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload{
  width: calc(100% - 120px);
  display: inline-block;
}

</style>
