<template>
  <div v-loading="loaders.resources" class="m-0 px-3 pt-4 pb-0">
    <div class="d-flex justify-content-between">
      <h1 class="m-0">Support Services</h1>
      <div class="d-flex justify-content-end align-items-center">
        <router-link class="ml-2" :to="{ name: 'SupportServiceSubCategory'}">
          <el-button
            class="button-hover-effect white-outline-button title-btn-padding button-font" type="secondary">
            Sub Category
          </el-button>
        </router-link>
        <router-link class="ml-2" :to="{ name: 'SupportServiceCategory'}">
          <el-button
            class="button-hover-effect white-outline-button title-btn-padding button-font" type="secondary">
            Category
          </el-button>
        </router-link>
        <router-link class="ml-2" :to="{ name: 'StoreSupportService'}">
          <el-button class="button-hover-effect btn-secondary-action title-btn-padding button-font">Add New</el-button>
        </router-link>
      </div>
    </div>

    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.resources"
        >Delete
        </base-button>
        <base-button class="mt-0 ml-2 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.resources"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 ml-2 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.resources"
        >Deactivate
        </base-button>
      </div>
      <el-table-draggable
        handle=".handle"
        @drop="updateSortList">
        <el-table
          class="table-responsive table-flush checklist-items-table checklist-table"
          header-row-class-name="thead-light"
          width="100%"
          ref="multipleSelectionTable"
          :data="response.data"
          @selection-change="supportServiceMultiSelect">
          <el-table-column align="left" :class-name="btnDraggable" width="50">
            <template slot-scope="scope">
              <div class="handle" v-if="scope.row.id !==''">
                <img src="/img/icons/buttons/handle.svg" alt="+">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="selected" type="selection">
          </el-table-column>
          <el-table-column label="SUPPORT SERVICE NAME" prop="supportServiceName">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                <span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="SUPPORT SERVICE CATEGORY" prop="supportServiceCategory">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                <span v-if="scope.row.support_service_category">{{ scope.row.support_service_category.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="SUPPORT SERVICE SUB CATEGORY" prop="supportServiceSubCategory">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                <span v-if="scope.row.support_service_sub_category">{{ scope.row.support_service_sub_category.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" prop="status">
            <template slot-scope="scope">
                <span :class="['status',getStatus(scope.row.status)]">
                  <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                  <span>{{ getStatus(scope.row.status) }}</span>
                </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS">
            <div slot-scope="{ $index, row }" class="d-flex">
              <router-link :to="{name: 'ShowSupportService', params: {id: row.id}}">
                <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
              </router-link>
              <router-link :to="{name: 'EditSupportService', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>
              <img
                class="pointer"
                @click="handleDelete($index, row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </div>
          </el-table-column>
        </el-table>
      </el-table-draggable>
    </div>

    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeSupportService"
        @onRemoveMultiple="deleteSupportService"
      />
    </div>
  </div>
</template>

<script>

import {Table, TableColumn} from "element-ui";
const RemoveModal = () => import(/* webpackChunkName: "RemoveModal" */ "@/components/Modals/RemoveModal.vue");
import ElTableDraggable from 'element-ui-el-table-draggable';

export default {
  name: "SupportService",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    ElTableDraggable,
  },
  data() {
    return {
      selectedRows: [],
      loaders: {
        resources: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      btnDraggable:'btnDraggable',
      response: {
        data: []
      },
      editId: ''
    }
  },
  created() {
    this.fetchSupportServices()
  },
  methods: {

    /**
     * Returns newOrderKey based on defined conditions
     * @param {number} oldOrderKey - Old Order Key
     * @param {number, string} prev - Prev Order Key
     * @param {number} curr - Current Order Key
     * @param {number, string} next - Next Order Key
     * @returns {number} newOrderKey
     */
    assignNewOrderKey(oldOrderKey, prev, curr, next){
      let newOrderKey
      if (prev === '') {
        newOrderKey = (curr < next || next === '') ? oldOrderKey : next
      } else if ( next === '') {
        newOrderKey = (curr > prev || prev === '') ? oldOrderKey : prev
      } else if (curr < prev && curr < next) {
        newOrderKey = prev
      } else if (curr > prev && curr > next) {
        newOrderKey = next
      } else if (curr > prev && curr < next) {
        newOrderKey = oldOrderKey
      }
      return newOrderKey
    },

    /**
     * Returns count of 0 order key
     * @param {*[]} list - List Object
     * @returns {number} orderCheckCounter
     */
    orderCheck(list) {
      let orderCheckCounter = 0
      for (const item of list) {
        if (item.order_key === 0) {
          orderCheckCounter++
          if (orderCheckCounter > 1) { break }
        }
      }
      return orderCheckCounter;
    },

    /**
     * Returns new payload based on defined conditions
     * @param {number} orderCheckCounter - Order Check Counter
     * @param {number} newOrderKey - New Order Key
     * @param {number} oldOrderKey - Old Order Key
     * @param {event} event - Event Object
     * @returns payload - Payload Object
     */
    assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event) {
      let payload
      if (orderCheckCounter > 1) {
        let orderKeyArray = []
        let order = 1;
        for (const item of event.list) {
          orderKeyArray.push({ id: item.id, order_key: order }) // Array having order set from Event
          order++
        }
        payload = { orderKeyArray }
      } else {
        payload = { id: event.targetObject.id, newOrderKey, oldOrderKey }
      }
      return payload
    },

    /**
     * Update Sorted List on drop of a row.
     * $param {event} event - Event object
     */
    async updateSortList(event){
      let vm = this
      let oldOrderKey = event.targetObject.order_key
      let newOrderKey
      let currIndex = event.list.findIndex(item => item.id === event.targetObject.id)
      let curr = event.list.find(item => item.id === event.targetObject.id).order_key
      let prev = (event.list[currIndex - 1]) ? event.list[currIndex - 1].order_key : ''
      let next = (event.list[currIndex + 1]) ? event.list[currIndex + 1].order_key : ''

      // This function assigns new order key based on defined conditions
      newOrderKey = this.assignNewOrderKey(oldOrderKey, prev, curr, next)

      let orderCheckCounter = 0 // Counter to check number of 0's in order

      // Pass list and counter to get counts of 0 order key
      orderCheckCounter = this.orderCheck(event.list)

      // Check if item is not drag to other position
      if (newOrderKey === oldOrderKey && orderCheckCounter < 2) { return }

      let payload // Initializing payload

      //This function assigns the payload based on defined conditions
      payload = this.assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event)

      const dispatchPayload = {
        contentMenu: 'support-service',
        payload
      }

      try{
        const response = await vm.$store.dispatch('_updateSortList', dispatchPayload); // Dispatch Api call to update sort list
        vm.response.data = response.data.data
      }
      catch(error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          message: message
        });
      }
    },

    /**
     * Fetch All Support Services and save it in response data.
     */
    async fetchSupportServices() {
      let vm = this
      vm.loaders.resources = true
      try {
        let response = await vm.$store.dispatch('_fetchSupportServices')
        vm.response.data = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          message: message
        })
      }
      vm.loaders.resources = false
    },

    /**
     * Handle the Delete Request
     * @param index - Index from the table
     * @param row - Row from the table on which event is occurred
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Get Status in number and returns its Value in String
     * @param {number} status - Status of the Support Service
     * @returns {string}
     */
    getStatus(status) {
      return (status === 0) ? 'Inactive' : 'Active'
    },

    /**
     * Handles the Status Activate request
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handles the Status Deactivate request
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Set new status value
     * @param {number} ids - Selected ids from the table
     * @param {boolean} status - Status of the selected ids
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.resources = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }
      try {
        vm.$store.dispatch('_updateStatusSupportService', payload)
        vm.fetchSupportServices()
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          message: message
        })
      }
      vm.loaders.resources = false
    },

    /**
     * Set value of selected rows
     * @param {number[]} selectedRows - Ids of selected rows from table
     */
    supportServiceMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Prompt the Remove Modal and set the remove id
     * @param row - Selected row from the table
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Support Service.';
      this.removeId = row.id;
    },

    /**
     * Prompt the Remove Modal and set the remove ids
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these Support Services.';
      this.removeId = this.extractSelectedIds;
    },

    /**
     * Handles the delete request of specific Support Service
     * @param {number} id - Id of the relevant support service
     */
    removeSupportService(id) {
      this.deleteRequest(id)
    },

    /**
     * Handles the delete request for Support Service
     */
    deleteSupportService() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Delete the Support service(s) for selected ids
     * @param {*[]} data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.resources = true
      vm.loaders.checkboxButton.Delete = true
      const payload = { ids: Array.isArray(data) ? data : [data] };
      try {
        vm.$store.dispatch('_deleteSupportService', payload)
        vm.$store.commit("hideRemoveModal");
        vm.removeId = []
        vm.fetchSupportServices()
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service',
          message: message
        })
      }
      vm.loaders.checkboxButton.Delete = false;
      vm.loaders.resources = false;
    },
  },
  computed: {
    /**
     * Extract the selected ids from the list of support service
     * @returns {number[]} selectedIds - Selected Ids from table
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>
.indicator {
  height: 7px;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.btnDraggable {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  cursor: grabbing;
  margin-left: 0;
}

.handle {
  cursor: grabbing;
}
</style>
